import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import usePortal from "react-cool-portal";

import ButtonAction from '../ButtonAction'
import BoxWhite from '../BoxWhite'

import * as S from './styled'

const CardMember = ({image, crmv, name, text}) => {

  const { Portal, show, hide } = usePortal({ defaultShow: false });

  // const handleClickBackdrop = (e) => {
  //   const { id } = e.target;
  //   if (id === "modal") hide();
  // };

  const { members } = useStaticQuery(
    graphql`
      query {
        members: allFile(filter: { name: {regex: "/member-/"}}) {
          edges {
            node {
              name
              childImageSharp {
                fixed(width: 200, height: 200) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    `
  )

  // console.log(members);
  const member = members.edges.filter((item) => {
    // console.log(item.node.name)
    return item.node.name === `member-${image}`
  });
  // console.log(member);

  const textList = text.split("#");

  return (
    <>
      <S.Col>
        <BoxWhite>
          <S.ImageWrapper fixed={member[0].node.childImageSharp.fixed} />
          <S.Title>{name}</S.Title>
          <S.Crmv>{crmv}</S.Crmv>
          <ButtonAction action={show} className="btn btn-primary btn-lg">Quem eu sou ?</ButtonAction>
        </BoxWhite>
      </S.Col>
      <Portal>
        <div
          id="modal"
          className="modal"
          // onClick={handleClickBackdrop}
          tabIndex={-1}
        >
          <div
            className="modal-dialog"
            role="dialog"
            aria-labelledby="modal-label"
            aria-modal="true"
          >
            <div className="modal-header">
              <h5 id="modal-label" className="modal-title">
                {name}
              </h5>
              <button
                className="modal-close"
                onClick={hide}
                type="button"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {textList.map(item => {
                return <p>{item}</p>
              })}
            </div>
          </div>
        </div>
      </Portal>
    </>
  );
}

CardMember.defaultProps = {
  image: "about",
  crmv: "crmv",
  name: "name",
  text: "text",
}

CardMember.propTypes = {
  image: PropTypes.string,
  crmv: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
}

export default CardMember;