import React from "react"
import { useStaticQuery, graphql } from 'gatsby'

import { Container } from 'reactstrap'
import SEO from "../components/seo"

import Layout from "../components/Layout"
import Banner from '../components/Banner'
import CardMember from '../components/CardMember'

import * as S from '../styles/quem-somos'

export default () => {
  const { banner } = useStaticQuery(
    graphql`
      query {
        banner: file(relativePath: {eq: "banner-about.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <SEO title="Quem Somos" description="Sobre a imaginopet e nossa equipe" />
      <Banner images={banner}/>
      <Container className="pt-4">
        <S.ContainerText>
          <h3>Conheça a nossa história</h3>
          <p>A equipe Imaginopet é composta pelas médicas veterinária Ana Paula Araújo Costa, Patrícia Nunes de Oliveira e Gabriela do Socorro Neves Soares, todas ex-residentes do Hospital Veterinário da Universidade Federal de Goiás, na área do Diagnóstico por Imagem. A proposta da equipe é fornecer serviço volante de atendimento ultrassonográfico às clínica veterinárias de Goiânia e região. Portanto, os animais atendidos por nós devem ter sido avaliados anteriormente por um médico veterinário, o qual é o responsável por solicitar o exame. Além de exame ultrassonográficos, a equipe realiza exames ecodopplercardiográficos e ecocardiográficos.</p>
        </S.ContainerText>
      </Container>
      <S.ContainerEquipe className="pt-4" id="equipe">
        <h3>Nossa Equipe</h3>
        <S.CardDiv>
          <CardMember image="ana-paula" crmv="CRMV-GO 4754" name="Ana Paula Araújo Costa" text="Médica Veterinária especializada em Diagnóstico por Imagem#Sócia fundadora da ImaginoPet#Residência em Diagnóstico por Imagem - UFG#Doutora em Ciência Animal - UFG#Aperfeiçoamento em Cardiologia Veterinária - UNESP / Jaboticabal#Membro e Diretora Científica da Sociedade Brasileira de Cardiologia Veterinária - Regional Centro Oeste"/>
          <CardMember image="patricia-nunes" crmv="CRMV-GO 5585" name="Patrícia Nunes de Oliveira" text="Médica Veterinária#Residência em Diagnóstico por Imagem - UFG"/>
        </S.CardDiv>
        <S.CardDiv>
          <CardMember image="gabriela-socorro" crmv="CRMV-GO 6862" name="Gabriela do Socorro Neves Soares" text="Médica Veterinária#Residência em Diagnóstico por Imagem - UFG"/>
          <CardMember image="allana-ferreira" crmv="CRMV-GO 8167" name="Allana Ferreira do Nascimento" text="Médica Veterinária#Residência em Diagnóstico por Imagem - UFG"/>
        </S.CardDiv>
      </S.ContainerEquipe>
    </Layout>
  )
}
