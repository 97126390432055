import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'
import { Col as bCol } from 'reactstrap'

export const Col = styled(bCol)`
  max-width: 400px !important;
  ${media.lessThan('small')`
    margin: 15px 0;
  `}
`

export const ImageWrapper = styled(Img)`
  /* margin: ${props => props.margin+'px'}; */
  /* margin: 30px; */
  /* padding: 30px; */
  /* border-radius: 50%;
  height: 3.75rem;
  margin: auto;
  width: 3.75rem;
  */
 border-radius: 50%;
`

export const Title = styled.h4`
  color: white;
  margin: 10px 0;
`

export const Crmv = styled.span`
  color: white;
  margin: 15px 0;
`

export const Text = styled.span`
  color: white;
  margin: 15px 0;
`